.dashTileHeight{
    max-height: 224px;
}
.incompleteProfile{
    border: solid 1px #f64e63!important;
    background-color: #fff;
}
.bgIcon{
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    width: 54px;
    height: 54px;
    text-align: center;
    line-height: 50px;
}
.bgIcon svg{
    transition: all .5s linear;
}
.bgIcon:hover svg{
    transform: scale(1.10);
    transition: all .5s linear;

}
.cartIcon{
    color:#f6ad4e;
    font-size: 30px;
}
.handIcon{
    color:#814cf7;
    font-size: 30px;
}
.fileIcon{
    color:#3dc9bc;
    font-size: 30px;
}
.diamondIcon{
    color:#0072ff;
    font-size: 30px;
}
.cartTile:hover,.OrderPlaceTiles:hover,.invoiceTile:hover,.diamondRequestTile:hover{
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14);;
}
.cartTile {
    /* background-image: linear-gradient( 326deg, #ffc480, #ff763b); */
    background: hsla(223, 100%, 37%, 1);
    background: linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0037BF", endColorstr="#00B7FF", GradientType=1 );
}
.OrderPlaceTiles {
    /* background-image: linear-gradient( 325deg, #759bff, #843cf6); */
    background: hsla(223, 100%, 37%, 1);
    background: linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0037BF", endColorstr="#00B7FF", GradientType=1 );
}
.invoiceTile{
    /* background-image: linear-gradient( 149deg, #26A69A, #44d4c7); */
    background: hsla(223, 100%, 37%, 1);
    background: linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0037BF", endColorstr="#00B7FF", GradientType=1 );
}
.diamondRequestTile{
    /* background-image: linear-gradient( 325deg, #0e4cfd, #6a8eff); */
    background: hsla(223, 100%, 37%, 1);
    background: linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(223, 100%, 37%, 1) 0%, hsla(197, 100%, 50%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0037BF", endColorstr="#00B7FF", GradientType=1 );
}
