@font-face {
    font-family: 'ZapfHumnst';
    src: url('./fonts/Zapf-Humanist-601-BT.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir';
    src: url('./fonts/AvenirLTStdBook.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Product Sans';
    src: url('./fonts/ProductSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}


.l30 {
    line-height: 30px;
}


.customH1Title{
    color: #000;
    font-size: 45px;
    letter-spacing: -1px;
    font-family: 'Product Sans', sans-serif;
}

.customH2Title{
    color: #000;
    font-size: 36px;
    font-family: 'Product Sans', sans-serif;
}
.customH3Title{
    color: #000;
    font-size: 22px;
    letter-spacing: -1px;
    font-family: 'Product Sans', sans-serif;
}
.customH4Title{
    color: #000;
    font-size: 20px;
    letter-spacing: -1px;
    font-family: 'Product Sans', sans-serif;
}
.customH5Title{
    color: #000;
    font-size: 18;
    letter-spacing: -0.5px;
    font-family: 'Product Sans', sans-serif;

}
.CustomUL ul li{
    color: #616161;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Avenir' , sans-serif;
}

.titleH2 {
    font-size: 45px !important;
}

.titleH3 {
    font-size: 36px !important;
}

.titleH4 {
    font-size: 28px !important;
}

.bgF6f8fc {
    background-color: #f6f8fc;
}

.bg002173 {
    background-color: #002173
}
.bg-cedbfc{
    background-color: #cedbfc;
}
.cF0b33f90 {
    color: #f0b33f90
}

.py100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.py80 {
    padding: 62px 0px;
}

.px65 {
    padding-right: 65px;
    padding-left: 65px;
}

.py65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.blogcategory {
    color: #f0b33f
}

.blogcategory, .blogdate {
    font-family: 'Product Sans', sans-serif;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 500;
}

.topbar .topbarSkype {
    color: #00aff0;
    border-left: 1px solid #616161;
    padding: 0px 10px;
    font-size: 20px;
    text-decoration: none;
    margin-left: .5rem!important;
}

.topbar .topNumber {
    padding: 0px 5px;
    font-size: 12px!important;
}

@media (min-width: 768px) {
    .footerlogo img {
        width: 200px !important;
    }
}

.topbar {
    background: #f1f1f1;
    top: 0;
    padding: 5px;
    z-index: 1000;
}

.appdownload {
    margin-top: 90px;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
    .contact_tab {
        height: 440px
    }

    footer .offset-1 {
        margin-left: 0%;
    }

}

@media (max-width: 320px) {
    .phoneimg img {
        width: 250px;
    }

    .whyitstarted img {
        width: 250px !important;
    }

    .wawd img {
        width: 250px !important;
    }

    footer .offset-1 {
        margin-left: 0%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

    .padleft {
        padding-left: 10px !important
    }

    .phoneimg img {
        width: 300px;
    }

    .appdownload {
        margin-top: 30px !important;
    }

    .center {
        text-align: center !important;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .blog img {
        width: 200px;
    }

    .phoneimg img {
        width: 400px;
        transform: translate(-15%, 0%);
    }
}

@media (min-width: 320px) {
    .footerLarge {
        display: none;
    }

    .footerSmall {
        display: block;
    }

    .phoneimg img {
        width: 300px;
    }

    .appdownload {
        margin-top: 0px !important;
    }

    .videobcg {
        left: -60% !important;
    }
}

@media (min-width: 576px) {
    .footerLarge {
        display: none;
    }

    .footerSmall {
        display: block;
    }

    .appdownload {
        margin-top: 0px !important;
    }

    .videobcg {
        left: -50% !important;
    }
}

@media (min-width: 768px) {
    .footerLarge {
        display: none;
    }

    .footerSmall {
        display: block;
    }
    .phoneimg img {
        width: 400px;
        transform: translate(-15%, 0%);
    }

    .appdownload {
        margin-top: 0px !important;
    }

    .videobcg {
        left: 0 !important;
    }
}

@media (min-width: 1023.5px) {
    .footerLarge {
        display: block;
    }

    .footerSmall {
        display: none;
    }
}

@media (min-width: 1200px) {
    .footerLarge {
        display: block;
    }

    .footerSmall {
        display: none;
    }

    .phoneimg img {
        width: 500px;
    }

    .appdownload {
        margin-top: 90px !important;
    }

    .videobcg {
        left: 0 !important;
    }
}

.appdownload h3 {
    font-size: 36px
}

.padleft {
    padding-left: 80px !important
}

.banner h1 {
    color: #fff
}

.banner .animatedText {
    color: #fff;
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.animatedText {
    font-size: 45px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 0;
    font-family: 'Product Sans', sans-serif;
    line-height: 1.2;
}

.animatedText span {
    font-size: 45px;
    font-weight: normal;
    color: #f0b33f;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Product Sans', sans-serif;
    line-height: 1.2;

}

.howitworkstep {
    padding: 3px 6px;
    background-color: #f3f3f3;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.howitworktop {
    margin-top: 80px
}

.pws .card {
    padding: 50px 25px;
    border-radius: 10px;
    margin: 5px;
    height: 294px;
    transition: all .3s ease-in-out;
    border: 1px solid rgb(0 0 0 / 7%);
}

.pws .card:hover {
    box-shadow: 0 15px 30px rgba(51, 51, 51, .1);
    transform: translateY(-10px);
}

.productHome .card {
    border: 0px;
    border-radius: 3px;
    width: 315px;
    height: 400px;
    margin: 15px auto;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all .3s ease-in-out;
}
.productHome .card:hover {
    box-shadow: 0 15px 30px rgba(51, 51, 51, .1);
    transform: translateY(-10px);
}
@media (min-width: 768px){
    .productHome .card {
        width: 325px;
        height: 400px;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .productHome .card {
        width: 290px;
        height: 390px;
    }
}
@media (min-width: 1200px){
    .productHome .card {
        width: 340px;
        height: 400px;
    }
}
.productSec {
    padding:50px 15px!important;
}

.banner {
    position: relative;
    height: 91vh;
    width: 100%;
    overflow: hidden;
}

.videobcg {
    z-index: -100;
    width: auto;
    min-width: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 0) !important;
    bottom: 0;
    object-fit: cover;
    position: absolute !important;
    height: 100% !important;
}

.homebanner {
    max-height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 40vh;
    transform: translate(-50%, -50%) !important;
    left: 50%;
    z-index: 10;
    max-width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .homebanner {
        left: 53%;
    }

    footer .offset-1 {
        margin-left: 0%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .homebanner {
        left: 52%;
    }
}

.banner:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 33 115 / 65%);
}


.navigationCleanButton {
    background: #fff;
    color: #333;
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
}


.navigationCleanButton .navbarBrand {
    font-weight: bold;
    color: inherit;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
}

.navigationCleanButton .navbarBrand:hover {
    color: #222;
}

.navigationCleanButton .navbarBrand img {

    display: inline-block;
    margin-right: 10px;
    width: auto;
}

.navigationCleanButton .navbar-toggler {
    border-color: #ddd;
}

.navigationCleanButton .navbar-toggler:hover,
.navigationCleanButton .navbar-toggler:focus {
    background: none;
}

.navigationCleanButton.navbar-dark .navbar-toggler {
    border-color: #555;
}

.navigationCleanButton .navbar-toggler {
    color: #888;
}

.navigationCleanButton.navbar-dark .navbar-toggler {
    color: #eee;
}

.navigationCleanButton .navbar-collapse,
.navigationCleanButton .form-inline {
    border-top-color: #ddd;
}

.navigationCleanButton.navbar-dark .navbar-collapse,
.navigationCleanButton.navbar-dark .form-inline {
    border-top-color: #333;
}

.navigationCleanButton .navbarNav a.active,
.navigationCleanButton .navbarNav>.show>a {
    background: none;
    box-shadow: none;
}

.navigationCleanButton.navbarLight .navbarNav a.active,
.navigationCleanButton.navbarLight .navbarNav a.active:focus,
.navigationCleanButton.navbarLight .navbarNav a.active:hover {
    color: #88919E;
    box-shadow: none;
    background: none;
    pointer-events: none;
}

.navigationCleanButton.navbar .navbarNav .navLink {
    padding-left: 18px;
    padding-right: 18px;
}

.navigationCleanButton.navbarLight .navbarNav .navLink {
    color: #465765;
}

.navigationCleanButton.navbarLight .navbarNav .navLink:focus,
.navigationCleanButton.navbarLight .navbarNav .navLink:hover {
    color: #002173;
    background-color: transparent;
}

.navigationCleanButton .navbarNav>li>.dropdown-menu {
    margin-top: 0px;
    margin-left: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.50);
    background-color: #fff;
    border-radius: 5px;
    border-top: 5px solid #9E9E9E;
}

.navigationCleanButton .dropdown-menu .dropdown-item:focus,
.navigationCleanButton .dropdown-menu .dropdown-item {
    line-height: 2;
    font-size: 16px;
    color: #212529;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.navigationCleanButton .dropdown-menu .dropdown-item:focus,
.navigationCleanButton .dropdown-menu .dropdown-item:hover {
    background: #002173;
    color: #fff;
}

@media (max-width:767px) {
    .navigationCleanButton .navbarNav .show .dropdown-menu {
        box-shadow: none;
    }
}

@media (max-width:767px) {
    .navigationCleanButton .navbarNav .show .dropdown-menu .dropdown-item {
        color: #002173;
        padding-top: 12px;
        padding-bottom: 12px;
        line-height: 1;
    }
}

.navbar .nav {
    font-size: 16px!important;
}

@media (max-width:767px) {
    .navigationCleanButton.navbar-dark .navbarNav .show .dropdown-menu .dropdown-item {
        color: #fff;
    }
}

.navigationCleanButton .actions .login {
    margin-right: 1rem;
    text-decoration: none;
    color: #465765;
}

.navigationCleanButton .navbarText .actionButton,
.navigationCleanButton .navbarText .actionButton:active,
.navigationCleanButton .navbarText .actionButton:hover {
    background: #002173;
    border-radius: 50px;
    font-size: inherit;
    color: #fff;
    box-shadow: none;
    border: none;
    text-shadow: none;
    padding: .5rem 1rem;
    transition: background-color 0.25s;
    font-size: inherit;
}

.navigationCleanButton .navbarText .actionButton:hover {
    background: #00164e;
}

.navbarLight .navbarText a:focus,
.navbarLight .navbarText a:hover {
    color: #fff;
}

@media (max-width: 1920px) {
    .navbarBrand img {
        width: 280px !important;
    }
}

@media (max-width: 2600px) {
    .navbarBrand img {
        width: 280px !important;
    }
}

@media (max-width:425px) {
    .navbarBrand img {
        width: 220px !important;
    }
}

@media (max-width:600px) {
    .navbarBrand img {
        width: 220px !important;
    }
}

@media (max-width:300px) {
    .navbarBrand img {
        width: 220px !important;
        margin-left: 0px;
    }
}

.btnPrimary {
    color: #fff!important;
    font-size: 16px!important;
    background-color: #002173!important;
    border-color: #002173!important;
    border-radius: 25px!important;
}

.btnPrimary:hover {
    color: #fff!important;
    background-color: #001344!important;
    border-color: #001344!important;
    border-radius: 25px!important;
}

.btnPrimary:not(:disabled):not(.disabled):active {
    color: #fff!important;
    background-color: #002173!important;
    border-color: #002173!important;
}

.btnPrimary:focus {
    color: #fff!important;
    background-color: #002173!important;
    border-color: #8d94a5;
    box-shadow: 0 0 0 0.2rem rgb(0 33 115 / 59%)!important;
}
.btnPrimary.disabled, .btnPrimary:disabled {
    pointer-events: auto!important;
    color: #fff!important;
    background-color: #002173!important;
    border-color: #002173!important;
    cursor: not-allowed!important;
}
.btnOutlinePrimary {
    font-size: 16px!important;
    color: #002173!important;
    border-color: #002173!important;
    padding: 10px 20px!important;
    border-radius: 25px!important;
}

.btnOutlinePrimary:hover {
    color: #fff!important;
    background-color: #002173!important;
    border-color: #001344!important;
    padding: 10px 20px!important;
    border-radius: 25px!important;
}

.btnOutlinePrimary:not(:disabled):not(.disabled):active {
    color: #fff!important;
    background-color: #002173!important;
    border-color: #002173!important;
}

.btnOutlinePrimary:focus {
    color: #002173!important;
    border-color: #002173!important;
    box-shadow: 0 0 0 0.2rem rgb(0 33 115 / 59%) !important;
}

.btnOutlinePrimary.focus,
.btnOutlinePrimary:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 33 115 / 51%);
}

.btnSecondary {
    font-size: 16px!important;
    color: #fff!important;
    background-color: #f0b33f!important;
    border-color: #f0b33f!important;
    border-radius: 25px!important;
    padding: 8px 20px!important;
}

.btnSecondary:hover {
    color: #002173!important;
    background-color: #fdfdfd!important;
    border-color: #ffffff!important;
}

.btnSecondary:not(:disabled):not(.disabled):active {
    color: #fff!important;
    background-color: #f0b33f!important;
    border-color: #f0b33f!important;
}

.btnSecondary:focus {
    color: #fff!important;
    background-color: #f0b33f!important;
    border-color: #f0b33f!important;
    box-shadow: 0 0 0 0.2rem #f8f9fa5e!important;
}

.btnOutlineSecondary {
    font-size: 16px!important;
    color: #ffffff!important;
    background-color: rgba(255, 255, 255, 0.08)!important;
    border-color: rgba(255, 255, 255, 0.08)!important;
    border-radius: 25px!important;
    padding: 8px 20px!important;
}

.btnOutlineSecondary:hover {
    color: #fff!important;
    background-color: rgba(255, 255, 255, 0.18)!important;
    border-color: rgba(255, 255, 255, 0.18)!important;
}

.btnOutlineSecondary.focus,
.btnOutlineSecondary:focus {
    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 0%)!important;
}

.btnOutlineSecondary:not(:disabled):not(.disabled):active {
    color: #ffffff!important;
    background-color: rgba(255, 255, 255, 0.08)!important;
    border-color: rgba(255, 255, 255, 0.08)!important;
}

.footer {
    background-color: #0f1b42;
    padding: 60px 30px;
}

.footer p {
    color: #ffffff80
}

.footer a {
    color: #ffffff80
}

.footer a:hover {
    color: #ffffff
}

.footerlogo img {
    width: 250px;
}

.footerbottom p {
    color: #fff;
    margin-bottom: 0px;
    font-size: 14px;
}

.footerbottom {
    padding: 5px 0px;
}

.footerbottom .fa {
    color: #fff;
    font-size: 30px;
    padding: 0px 5px;
    text-align: right;
}

.myFooter .nestedA {
    line-height: 1.2;
    margin-bottom: 15px;
    margin-top: -3px;
}

.myFooter .footerSmHead {
    color: #f0b33f90;
    font-size: 14px;
    letter-spacing: 1.2px;
    font-weight: 600;
    line-height: 2.5;
}

.myFooter .nestedA a {
    font-size: 11px;
    letter-spacing: 0.5px;
}

.myFooter {
    background-color: #0f1b42;
    color: white;
    padding: 60px 30px;
}

.myFooter p {
    color: #ffffff80;
    font-size: 14px;
}

.myFooter h5 {
    color: #f0b33f90;
    font-size: 14px;
    letter-spacing: 1.2px;
}

.myFooter ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 2.2;
}

.myFooter a {
    color: #ffffff80;
    text-decoration: none;
    font-size: 14px;
}

.myFooter a:hover,
.myFooter a:focus {
    text-decoration: none;
    color: white;
}

.contact_banner_text {
    width: 100%;
    text-align: center;
    margin: 140px 0 0 0;
}

.jumbotron {
    border-radius: 24px;
    position: relative;
    background-image: url('../Images/bgimage.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #002173;
    padding: 75px 20px;
}

.jumbotron h2 {
    color: #fff
}

.jumbotron p {
    color: #fff
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .conact_map {
        margin: 56px 0px 0px 0px !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {

    .web_contact_banner h1 {
        margin-top: 7vh;
    }

    .conact_map {
        margin: 60px 0 0 80px !important;
    }
}

.ct_sales a {
    font-size: 18px;
    color: #616161;
    font-family: 'Avenir', sans-serif;
}

.ct_sales a:hover{
    color: #f0b33f;
}

.ct_writeus p{
    color: #616161;
    font-size: 16px; 
}
.ct_writeus{
    margin-bottom: 65px;
}

.ct_writeus a{
    color: #002173;
    font-size: 20px;
    font-weight: 600;
}

.ct_writeus a:hover{
    color: #f0b33f;
}

.ct_sales h4 {
    font-size: 22px;
    color: #002173;
}
.ct_bottom_add{
    margin-top:45px;
    margin-bottom:45px;  
}

.ct_bottom_add h3{
    font-size: 36px;
    margin-bottom: 20px;
}

.ct_bottom_add span{
    font-size: 12px;
    color: #141414;
    text-transform: uppercase;
    font-weight: 600;
}

.send_us_message {
    width: 90%;
    margin: 30px auto;
}

.web_send_us_message {
    background: #f6f8fc;
    padding: 40px 0;
}

.showpwd{
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    font-size: 19px;
}
.showpwd:hover{
    color: #f0b33f;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f3f6f900;
    border: 0px solid #f3f6f900;
    border-radius: 0.85rem 0 0 0.85rem;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: #f3f6f900;
    border-radius: 0.85rem 0 0 0.85rem;
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background: #f3f6f9;
    border-radius: 0.85rem 0 0 0.85rem;
}
.ReactFlagsSelect-module_selectBtn__19wW7{
    font-family: 'Avenir';
    width: 100%;
    font-size: 16px;
    color: #3F4254;
    background: #F3F6F9;
    border: 1px solid #F3F6F9;
    margin-bottom: 0px;
    height: 50px;
    padding: 10px 15px;
    border-radius: 0.85rem;
}

.web_contact_map {
    width: 100%;
    height: 433px;
}

.mapCanvas {
    width: 100%;
    height: 433px;
}

.vcol {
    min-height: 330px;
    padding: 20px;
    border: 1px solid #cec9c9;
    transition: all .3s ease-in-out;
    background-color: #fff;
}

.vcol:hover {
    box-shadow: 0 15px 30px rgba(51, 51, 51, .1);
    transform: translateY(-10px);
    border-radius: 15px;
}

.forwhom img {
    float: left;
    margin-left: -65px;
    height: 40px;
}

.forwhomitem {
    min-height: 100px;
    padding-left: 80px!important;
    margin-bottom: 40px;
}

.abouttitle {
    border-radius: 0px;
    position: relative;
    background-image: url('../Images/bgimage.jpg');
    background-color: #002173;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 75px 20px;
}

.abouttitle h1 {
    color: #fff;
}

.parallax {
    margin-top: 250px;
    position: relative;
    background-image: url('../Images/whyus-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 75px 20px;
    background-attachment: fixed;
    background-position: center;
}

.parallax:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 33 115 / 60%);
}

.parallax p {
    color: #fff
}

.parallax h2 {
    color: #fff
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .companyimg img {
        width: 345px !important;
    }

    .wawd img {
        width: 280px !important;
    }

    .whyitstarted img {
        width: 318px !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .companyimg img {
        width: 700px !important;
    }
}

.accordionButton {
    margin-bottom: 0!important;
    border-bottom: none!important;
    font-size: 22px!important;
    color: #002173;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

.accordionBody .accordionItem .accordionBody{
    font-size: 14px!important;
}

.accordionBody .accordionButton:not(.collapsed) {
    font-size: 18px!important;
    color: #000000;
    font-weight: 500;
}
.accordionBody .accordionBody{
    background-color: #f6f8fc;
    font-size: 1rem!important;
}

.accordionItem{
    margin-bottom: 5px;
}

.homebanner h1 {
    font-size: 45px;
    font-weight: normal;
    line-height: 1.2;
}

.block1 {
    padding: 7% 3%;
}

.videowhy video {
    max-width: 80% !important;
}

.teamClean.bgblue {
    background-color: #f3fbfb;
}

.teamClean {
    color: #313437;
    background-color: #fff;
}

.teamClean .intro {
    font-size: 16px;
    max-width: 800px;
    margin: 0 auto;
}

.teamClean.bgblue {
    background-color: #f3fbfb;
}

.teamClean {
    color: #313437;
    background-color: #fff;
}

.teamClean .intro {
    font-size: 16px;
    max-width: 800px;
    margin: 0 auto;
}

.p03 {
    padding: 3% 3%;
}

.newboarder {
    position: absolute;
    margin: 6em 25em;
    background-color: orange;
    height: 1px;
    margin-left: 4%;
    text-align: center;
}

@media (max-width:2560px) {
    .newboarder {
        width: 94%;
        margin-left: 2%;
        margin: 6em 0 3em 16em;
    }
}

@media (max-width:1920px) {
    .newboarder {
        width: 92%;
        margin-left: 3% !important;
        margin: 6em 0em 4em 13em;
    }
}

@media (max-width: 1460px) {
    .newboarder {
        width: 90%;
        margin: 6em 0 2em 10em;
    }
}

@media (max-width: 1280px) {
    .newboarder {
        margin: 6em 0 2em 8em;
    }
}

@media (max-width: 1024px) {
    .newborder {
        width: 86%;
        margin: 6em 0 2em 7em;
    }
}

.flexcolumn {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    padding: 0 4%;
}

@media screen and (max-width: 992px) {
    .flexcolumn {
        flex: 50%;
        padding: 0 1%;
    }
}

@media (max-width:767px) {
    .newboarder {
        display: none !important;
    }

    #shipping .btnShipping {
        display: none;
    }

    .block1 {
        padding-top: 0px !important
    }

    .block1 {
        padding: 0% 3%;
        padding-top: 10%;
    }

    .p03 {
        padding: 0% 0%;
    }

    .teamClean .item img {
        padding-top: 0em;
        ;
    }
}

.teamClean .item img {
    margin: 0 auto;
}

.videowhy {
    padding-top: 2%
}

@media only screen and (max-width:767px) {
    .videowhy video {
        max-width: 80% !important;
        text-align: center;
        margin: 0;
    }
}

@media (max-width:411px) {
    .videowhy video {
        max-width: 100% !important;
    }

}

#checkingsteps {
    position: relative;
}

#checkingsteps img {
    max-width: 160px;
    text-align: center;
    display: inline;
}

#checkingsteps {
    text-align: center;
    margin: 0 auto;
}

#checkingsteps {
    padding-bottom: 2em;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
}

#checkingsteps .btnShipping {
    background: #fff;
    border-radius: 6px;
    width: auto;
    height: 35px;
    font-weight: 600;
    border: 1px solid orange;
    line-height: 35px;
    padding: 0 1.5rem;
    display: inline-block;
    font-size: 1rem;
    color:black
}

#checkingsteps .step_quality {
    text-align: center;
    padding: 0% 3%;
    margin: 0 auto;
}

#checkingsteps .step_quality .name {
    font-weight: 700;
    margin-top: 28px;
    margin-bottom: 8px;
    color: inherit;
}

.getInTouch p {
    color: #fff;
}

.name2 {
    color: #002173 !important;
    font-size: 14px;
}

.name2 {
    font-size: 16px;
}


.imgbg {
    background-color: #f6f8fc
}

.about-image img {
    border: 0 none;
    height: auto;
    max-width: 100%;
    display: block;
}

.web_login {
    width: 100%;
    padding: 80px 0;
    position: relative;
    background-color: #fff;
}

.web_login h1 {
    font-size: 30px;
    color: #000;
    text-align: center;
}

.clear {
    clear: both;
}

.login_form {
    margin: 0 auto;
    width: 450px;
    padding: 10px 15px;
}

@media (max-width: 480px) {
    .login_form {
        width: 100%;
        padding: 10px 0;
    }
}

.web_reqest_demo {
    width: 100%;
    background: url(../Images/bgimage.jpg) no-repeat;
    background-position: 100% 100%;
    background-size: cover;
    height: 350px;
}

.web_reqest_part {
    width: 100%;
}

.reqest_demo_text {
    width: 100%;
    padding: 120px 0 0 0;
}

.reqest_demo_text h1 {
    font-size: 36px;
    color: #FFF;
    margin: 0 0 10px 0;
    line-height: 46px;
}

.reqest_demo_text p {
    font-size: 18px;
    color: #FFF;
}

.reqest_tab {
    margin: 70px 0 0 50px;
}

.reqest_tab h2 {
    font-size: 26px;
    margin: 0 0 20px 0;
}

.reqest_tab ul li {
    list-style: none;
    margin: 14px 0;
}

.reqest_tab ul li i {
    display: inline-block;
    font-size: 20px;
    color: #f0b33f;
    line-height: 32px;
    padding-right: 15px;
}

.reqest_tab ul li span {
    display: inline-block;
    font-size: 17px;
    color: #333;
    width: 80%;
    vertical-align: top;
}

.reqest_form {
    width: 100%;
    padding: 30px 15px;
    background: #FFF;
    margin-top: -250px;
    margin-bottom: 50px;
    box-shadow: 0 14px 2.188em rgba(0, 0, 0, 0.16);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .reqest_form {
        margin-top: 0px;
    }

    .reqest_tab {
        margin: 33px 0px;
    }

    .reqest_demo_text {
        padding: 75px 0 0 0;
    }

    .reqest_demo_image img {
        width: 351px;
        margin-top: 30px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023.5px) and (-webkit-min-device-pixel-ratio: 1) {
    .reqest_form {
        margin-top: 20px;
    }

    .reqest_demo_text {
        padding: 85px 0 0 0;
    }

    .reqest_demo_image img {
        width: 351px;
    }
}

.socialicon i {
    color: #fff;
    padding: 6px;
    border-radius: 50%;
    text-align: center;
    align-items: center !important;
    font-size: 17px;
    height: 30px;
    width: 30px;
    margin: 0px 5px;
}

.socialicon p {
    margin-bottom: 0px;
    padding-top: 3px;
    font-family: ProductSans;
    font-size: 14px;
    font-weight: 500;
}

.tags a {
    color: #000000;
    font-size: 12px;
    border-radius: 4px;
    border: solid 1px #cedbfc;
    background-color: #f6f8fc;
    padding: 6px;
    line-height: 2.9;
    transition: all .3s ease-in-out;
}

.tags a:hover {
    color: #000000;
    border: solid 1px #8dadfe;
    background-color: #e3ecff;
    ;
    box-shadow: 0px 0px 4px 1px rgba(0, 123, 255, .25);
}

.blog a {
    color: #f0b33f;
    font-size: 12px;
    font-weight: 600;
}

.blog a:hover {
    color: #002173;
}

.blog p {
    font-size: 14px;
    font-weight: normal;
}
.BlogImg{
    height: 180px;
    overflow: hidden;
    width: 330px;
    position: relative;
}
@media (min-width: 576px){
    .BlogImg{
        height: 170px;
        width: 330px;
    }
}
@media (min-width: 768px){
    .BlogImg{
        width: 215px;
        height: 110px;
    }
}
@media (min-width: 992px){
    .BlogImg{
        width: 290px;
        height: 150px;
    }
}
@media (min-width: 1200px){
    .BlogImg{
        width: 350px;
        height: 190px;
    }
}
.BlogImg img:hover{width: 102%}
.BlogImg img{
    transition: all .3s ease-in-out;
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.mapCanvas .gmnoprint ,
.mapCanvas .gm-svpc,
.mapCanvas .gm-style button.gm-fullscreen-control,
.mapCanvas .gm-style-mtc button{
    display: none!important;
    visibility: hidden!important;
    opacity: 0!important;
}
.videoshowcasetitle{
    background-color: #0f1b42;
}
.showcase {
    top:0px;
    width: 100%;
    height: 275px;
    padding-top: 30px;
    margin-bottom: 0%;
    overflow: hidden;
    position: relative;
    background-color: #0f1b42;
}
.showcaseInner {
    width: 100%;
    height: 100%;
    background: #ffffff00;
    margin: 0 auto;
    cursor: pointer;
}
.showcaseInner video{
    width:100%;
}
@media (min-width:527px){
    .showcase{
        padding-top: 40px;
        height: 390px;
        min-height: 390px;
    }
}
@media (min-width:590px){
    .showcase{
        padding-top: 40px;
        height: 450px;
        min-height: 450px;
    }
}
@media (min-width:650px){
    .showcase{
        padding-top: 40px;
        height: 485px;
        min-height: 485px;
    }
}
@media (min-width:768px){
    .showcase{
        padding-top: 50px;
        height: 540px;
        min-height: 540px;
    }
}
@media (min-width:890px){
    .showcase{
        padding-top: 50px;
        height: 620px;
        min-height: 620px;
    }
}
@media (min-width:992px){
    .showcase{
        padding-top: 50px;
        height: 665px;
        min-height: 665px;
    }
}

@media (min-width:1100px){
    .showcase{
        padding-top: 100px;
        height:  760px;
        min-height:  760px;
        padding-bottom: 0%;
    }
    .showcaseInner {
        width: 80%;
        height: 80%;
        min-height: 80%;
    }
}
@media (min-width:1210px){
    .showcase{
        padding-top: 70px;
        height:  780px;
        min-height:  780px;
        padding-bottom: 0%;
    }
}
@media (min-width:1450px){
    .showcase{
        padding-top: 55px;
        height:  990px;
        min-height:  990px;
    }
}
.videoshowcaseBtn a {
    position: relative;
    z-index: 0;
    width: 60px;
    height: 60px;
    display: inline-block;
    background: #f0b33f;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    text-align: center;
    line-height: 62px;
    font-size: 19px;
    color: #002173;
    box-shadow: 0px 0px 20px -6px #ffc107;
}
.videoshowcaseBtn a span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #f0b33f;
    z-index: -1;
    animation: circle-fade-before 2s infinite ease-in-out;
    -webkit-animation: circle-fade-before 2s infinite ease-in-out;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}
.videoshowcaseBtn a .circle2 {
    animation: circle-fade-after 2s infinite ease-in-out;
    -webkit-animation: circle-fade-after 2s infinite ease-in-out;
}
@keyframes circle-fade-before {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
@keyframes circle-fade-after {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}
a.videoshowcaseBtnLink{
    padding-left: 10px;
    color: #fff;
    font-family: 'Product Sans';
    font-size: 20px;
    padding-top:5px;
}
a.videoshowcaseBtnLink:hover{
    color: #f0b33f;
}

.hiw{
    padding: 0 15px;
}
.hiw .hiwborder1{
    position: relative;
    border:none;
    z-index: 1;
    margin-bottom:15px;
}
.hiw .hiwborder1 .hiwLeftborder{
    border:none;
    z-index: 1;
}
.hiw .hiwborder1 .circle1{
    position: absolute;
    left: 22px;
    top: -28px;
    border: 2px solid;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    color: #002173;
    background-color: #fff;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    z-index: 2;
}
.hiw .hiwborder2{
    border:none;
    position: relative;
    z-index: 1;
    margin-bottom:15px;
}
.hiw .hiwborder2 .circle2{
    position: absolute;
    left:22px;
    top: -28px;
    border: 2px solid;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    color: #002173;
    background-color: #fff;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    z-index: 2;
}
.hiw .hiwborder3{
    border:none;
    position: relative;
    z-index: 1;	
    margin-bottom:15px;
}
.hiw .hiwborder3 .hiwLeftborder{
    border:none;
    z-index: 1;
}
.hiw .hiwborder3 .circle3{
    position: absolute;
    left: 22px;
    top: -28px;
    border: 2px solid;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    color: #002173;
    background-color: #fff;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    z-index: 2;
}
@media (min-width: 768px)	{
    .hiw{
        padding: 0 30px;
    }
    .hiw .hiwborder1{
        border-bottom:2px dashed #E6E6E6;
        margin-bottom:0px;
    }
    .hiw .hiwborder1 .hiwLeftborder{
        border-left:2px dashed #E6E6E6;
        position: absolute;
        top: 31%;
        bottom: 0;
        left:0;
        z-index: 1;
    }
    .hiw .hiwborder1 .circle1{
        left: -20px;
        top: 52px;
    }
    .hiw .hiwborder2{
        border:2px dashed #E6E6E6;
        border-top:none;
        border-left:none;
        margin-bottom:0px;
    }
    .hiw .hiwborder2 .circle2{
        right: -21px;
        left:auto;
        top: 53px;
    }
    .hiw .hiwborder3{
        margin-bottom:0px;
    }
    .hiw .hiwborder3 .hiwLeftborder{
        border-left:2px dashed #E6E6E6;
        position: absolute;
        top: 0;
        bottom: 66%;
        left:0;
        z-index: 1;
    }
    .hiw .hiwborder3 .circle3{
        left: -19px;
        top: 45px;
    }
}
@media (min-width: 1000px){
    .hiw{
        padding: 0 85px;
    }
}
.notFoundvh{
    height: 44.4vh;
}

.navbnavbarExpandXl .navbar-collapse {
    justify-content: flex-end;
}
.mapCanvas .customMap{
    height: 433px;
    width: 100%;
}