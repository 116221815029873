.maplink{
    cursor:pointer;
    padding:20px;
    margin: 0px 15px;
    min-height: 220px;
}
.maplink:hover{
    border-radius: 15px;
    padding: 20px;
    border: 1px solid #cec9c95c;
    background: rgb(246, 248, 252);
    background: linear-gradient(357deg, rgba(246, 248, 252, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
}
.activemaplink{
    border-radius: 15px;
    padding: 20px;
    border: 1px solid #cec9c95c;
    background: rgb(246, 248, 252);
    background: linear-gradient(357deg, rgba(246, 248, 252, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
}
.activemaplink h3{
    color:#002173;
}